import { Injectable } from '@angular/core';
import { getLocalStorage } from '@logic-suite/shared/utils/getLocalStorage';
import { BehaviorSubject, filter, Observable, startWith, tap } from 'rxjs';
import { Customer } from './customer.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class CustomerSelectionService {
  private customer$ = new BehaviorSubject<Customer>(null as unknown as Customer);
  private obs$ = this.customer$.asObservable();
  selectedCustomer = toSignal(this.obs$);

  hasCustomer() {
    return this.customer$.getValue() != null;
  }

  getCustomer(): Observable<Customer> {
    if (!this.hasCustomer()) {
      const customerStr = getLocalStorage().getItem('customer');
      if (customerStr) {
        const customer = JSON.parse(customerStr);
        this.setCustomer(customer, false);
      }
    }
    return this.obs$;
  }

  setCustomer(customer: Customer, persist = true) {
    if (!customer || customer.customerID !== this.customer$.getValue()?.customerID) {
      this.customer$.next(customer);
      if (persist) {
        getLocalStorage().setItem('customer', JSON.stringify(customer));
      }
    }
  }
}
