import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryOn504 } from '@logic-suite/shared/utils';
import { RequestCache } from '@logic-suite/shared/cache';
import { map, Observable, of, switchMap } from 'rxjs';
import { CustomerSelectionService } from './customer-selection.service';
import { Customer } from './customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient, private selection: CustomerSelectionService, private cache: RequestCache) {}

  getCustomers() {
    return this.http.get<Customer[]>(`/api/flxadmin/Customer`).pipe(
      retryOn504(),
      map(customers => {
        // Backward compatibility with api
        return customers.map(c => {
          if (typeof c.logoUrl === 'string' || c.logoUrl == null) {
            c.logoUrl = {
              light: c.logoUrl,
              dark: c.logoUrl,
            };
          }
          return c;
        });
      })
    );
  }

  hasCustomer() {
    return this.selection.hasCustomer();
  }

  getSelectedCustomer(): Observable<Customer> {
    const obs$ = this.selection.getCustomer();
    return obs$.pipe(
      switchMap(customer => {
        // No customer is set. Load the list of available customers
        if (customer == null) {
          return this.getCustomers().pipe(
            switchMap((customers: Customer[]) => {
              // If user only has access to one, set it
              if (customers.length === 1) {
                this.setCustomer(customers[0]);
                return obs$;
              }
              return of(null as unknown as Customer);
            })
          );
        }
        return obs$;
      })
    );
  }

  setCustomer(customer: Customer) {
    this.selection.setCustomer(customer);
    this.cache.invalidate();
  }
}
